import React from "react"
import { Columns, Content, Heading } from "react-bulma-components"

import FadeIn from "react-fade-in"

import Page from "../components/page"
import * as Styles from "./markdown-page.module.scss"


const MarkdownPage = (props) =>
	<Page>
		<FadeIn>
			<Columns
				centered
				multiline
				className={ Styles.container }>
				<Columns.Column
					mobile={ { size: 12 }}
					tablet={ { size: "two-thirds" }}>

					<div className={ Styles.scrollWrapper }>
						<div className={ Styles.contentWrapper }>
							<Heading
								size={ 1 }
								textColor={ "primary" }
								renderAs={ "h1" }>
								{ props.pageContext.frontmatter.title }
							</Heading>

							<hr className={ "has-margin-bottom-xl" }/>

							<Content
								className={ Styles.content }
								dangerouslySetInnerHTML={ { __html: props.pageContext.html } }
							/>
						</div>
					</div>
				</Columns.Column>
			</Columns>
		</FadeIn>
	</Page>;


export default MarkdownPage;